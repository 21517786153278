import styled from 'styled-components'
import mediaQueries from '../constants/mediaQueries'

const Styled = {
    Slider: styled.div`
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 9;
        will-change: transform;
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        padding-bottom: 10vh;

        ${mediaQueries.md} {
            padding-bottom: 0;
        }
    `,
    Nav: styled.div`
        position: absolute;
        bottom: 17.5vh;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        height: 2em;
        width: 100%;
        left: 0;
        text-align: center;
        opacity: 0;
        ${mediaQueries.md} {
            display: none;
        }
    `,
    NavButton: styled.button`
        -webkit-appearance: none;
        background: transparent;
        border: none;
        width: 100px;

        svg {
            stroke: currentColor;
            height: 1.5em;
            width: auto;
        }
    `,
    Item: styled.div`
        position: relative;
        display: flex;
        align-items: stretch;
        justify-content: center;
        pointer-events: none;
        will-change: transform;
        width: 80vw;
        height: 50vh;

        ${mediaQueries.sm} {
            width: 60vw;
        }

        ${mediaQueries.md} {
            width: 25vw;
            height: 60vh;
        }

        &:before {
            content: '';
            display: block;
            padding-bottom: 125%;
        }
    `,
    Content: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        z-index: 1;
        user-select: none;
        align-items: center;
    `,
    HeadingWrap: styled.div`
        display: flex;
        overflow: hidden;
        text-align: center;
        flex: 1;
        align-items: flex-end;
    `,
    Heading: styled.h3`
        pointer-events: none;
        color: #fff;
        transform-origin: left bottom;
        will-change: transform;
        font-size: 36px;
        font-weight: 900;
    `,
    Image: styled.div`
        overflow: hidden;
        filter: contrast(0.75) brightness(0.75);

        &,
        & .gatsby-image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .gatsby-image-wrapper {
            ${mediaQueries.sm} {
                max-width: unset;
                pointer-events: none;
                user-select: none;
                transform-origin: left center;
                transform: scale(1.75);
                will-change: transform;
            }

            img,
            picture {
                object-fit: cover;
            }
        }
    `,
}

export default Styled
