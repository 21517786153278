import React, { useEffect } from 'react'
import styled from 'styled-components'
import mediaQueries from '../constants/mediaQueries'
import Scroll from '../controllers/scroll'

const S = {
    Container: styled.div`
        display: grid;
        grid-column-gap: 2vw;
        align-items: center;
        padding: 0 4.167vw;
        height: 100vh;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;

        ${mediaQueries.sm} {
            padding: 0 8.33333vw;
            height: unset;
            cursor: grab;
        }
    `,
}

const Container = ({ children, updateScroll }) => {
    useEffect(() => {
        updateScroll(new Scroll())
    }, [])

    return (
        <S.Container className="container js-container" data-scroll>
            {children}
        </S.Container>
    )
}

export default Container
