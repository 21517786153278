import React from 'react'
import styled from 'styled-components'
import mediaQueries from '../constants/mediaQueries'
import colors from '../constants/colors'

const S = {
    Progress: styled.div`
        display: none;
        ${mediaQueries.md} {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: ${colors.red};
            transform: scaleX(0);
            transform-origin: left center;
            will-change: transform;
        }
    `,
    ProgressWrap: styled.div`
        display: none;
        ${mediaQueries.md} {
            display: block;
            position: absolute;
            left: 16.66667vw;
            bottom: 3.125vw;
            width: 66.66667vw;
            height: 1px;
            overflow: hidden;
            background-color: #d6d6d6;
            transform-origin: center center;
            will-change: transform;
            transform: ${(props) => (props.loaded ? `scaleX(1)` : `scaleX(0)`)};
        }
    `,
}

const Progress = ({ loaded }) => {
    return (
        <S.ProgressWrap
            loaded={loaded}
            className="progress-wrap js-progress-wrap"
        >
            <S.Progress className="slider-progress js-progress"></S.Progress>
        </S.ProgressWrap>
    )
}

Progress.defaultProps = {
    loaded: false,
}

export default Progress
