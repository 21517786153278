import React from 'react'
import styled from 'styled-components'
import mediaQueries from '../constants/mediaQueries'

const S = {
    Wrap: styled.div`
        display: flex;
        overflow: hidden;
        text-align: center;
        flex: 1;
        pointer-events: all;
    `,
    Button: styled.a`
        margin: 4.167vw auto 0;
        color: #fff;
        display: flex;
        font-size: 18px;
        line-height: 1.8;
        text-transform: uppercase;
        text-decoration: none;
        align-items: center;
        height: 11.111vw;
        background: transparent;
        border: 0;
        appearance: none;
        font-weight: 700;

        ${mediaQueries.sm} {
            margin: 1.389vw auto;
            height: 2.778vw;
        }
    `,
}

const Button = ({ link, children }) => {
    return (
        <S.Wrap className="button-wrap item-button-wrap">
            <S.Button className="button js-button item-button" href={link}>
                <span className="button-title-mask">
                    <div className="button-title-wrap">
                        <span className="button-title">{children}</span>
                    </div>
                </span>
            </S.Button>
        </S.Wrap>
    )
}

export default Button
