export default class SliderItem {
    constructor(el) {
        this.el = el
        this.img = this.el.querySelector('.js-item-img')
        this.button = this.el.querySelector('.js-button')
        this.bounds = this.el.getBoundingClientRect()
        this.x = 0
        this.visible = false
    }
}
